<template>
  <v-dialog v-model="active" persistent max-width="500" @keydown.esc="close" @input="close">
    <v-card>
      <v-toolbar short flat>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Bulk Edit</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()" :disabled="!this.changeToVal" :loading="saving">
          Update
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-select
                item-value="name"
                :items="fields"
                hide-details
                dense
                label="Edit Attribute"
                outlined
                @change="onEditAttrChange"
              >
              </v-select>
            </v-col>
            <v-col cols="12" v-if="editAttr">
              <v-card outlined>
                <v-card-subtitle> Current Value </v-card-subtitle>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ nameField.text }}
                        </th>
                        <th class="text-left">
                          {{ editAttr.text }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in selected" :key="item.name">
                        <td>{{ item[nameField.name] }}</td>
                        <td>{{ item[editAttr.name] }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="editAttr">
              <InputControl
                :attrId="editAttr.name"
                label="Change to"
                :attrType="editAttr.input.type"
                :attrRef="editAttr.input.lov"
                :multiValue="editAttr.input.multiple"
                :control="editAttr.input.control"
                :rules="editAttr.input.validation"
                @update:value="onUpdateValue($event)"
                :bind="{ outlined: true, ...editAttr.input.bind }"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputControl from "@/components/util/InputControl";

export default {
  components: {
    InputControl
  },
  props: {
    fields: {
      default: []
    },
    nameField: {
      default() {
        return { name: null, text: "Name" };
      }
    }
  },
  data() {
    return {
      editAttr: undefined,
      active: false,
      loading: false,
      saving: false,
      selected: [],
      changeToVal: null
    };
  },

  methods: {
    onUpdateValue(value) {
      // do ajax
      this.changeToVal = value;
    },
    onEditAttrChange(v) {
      let field = this.fields.find((f) => f.name == v);
      if (field) {
        this.editAttr = field;
      }
    },
    error() {
      this.saving = false;
    },
    save() {
      this.saving = true;
      this.$emit("update", { v: this.changeToVal, f: this.editAttr.name });
    },
    close() {
      this.changeToVal = null;
      this.editAttr = undefined;
      this.saving = false;
      this.active = false;
    },
    open(selected) {
      this.active = true;
      this.selected = selected;
    }
  }
};
</script>
