<template>
  <div>
    <template v-if="id">
      <CRUDForm
        :id="id"
        :fields="editable"
        :readOnly="table.components.edit == false"
        ref="crudForm"
        @save="onFormSave"
        :formSize="formSize"
        :headerText="formHeaderText"
        :loading="loading.form"
        @close="onFormClose"
        @form:input="$emit('form:input', $event)"
        :formActive="formActive"
        :alwaysPostAll="alwaysPostAll"
      >
        <template v-slot:form="{ fields, close }">
          <slot
            name="form"
            :fields="fields"
            :id="id"
            :headerText="formHeaderText"
            :formSize="formSize"
            :close="close"
          ></slot>
        </template>
        <template v-for="field in fields" v-slot:[`form.input.${field.name}`]="{ item, values }">
          <slot :name="`form.input.${field.name}`" :item="item" :values="values" :id="id"></slot>
        </template>
        <template v-slot:[`form.append.inner`]>
          <slot name="form.append.inner" :id="id"> </slot>
        </template>
        <template v-slot:[`form.append.outer`]>
          <slot name="form.append.outer" :id="id"> </slot>
        </template>
      </CRUDForm>
    </template>
    <CRUDBulkEditForm
      v-else
      ref="bulkEditForm"
      :fields="bulkEditable"
      :nameField="
        fields.find((f) => {
          return f.name == (table.nameIdentifier ? table.nameIdentifier : table.key);
        })
      "
      @update="onBulkEdit"
    />

    <CRUDApiError
      v-if="ApiError"
      :ApiError="ApiError"
      :cardTitle="table.text"
      :page="'listing'"
    ></CRUDApiError>

    <v-card v-else :loading="loading.table" v-show="!id || (id && formSize != 'full')">
      <v-toolbar short flat>
        <v-toolbar-title>{{ table.text }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <CRUDFilterMenu
          v-if="table.components && table.components.filter"
          :fields="searchable"
          @update="onFilter"
        >
          <template v-for="field in fields" v-slot:[`filter.${field.name}`]="{ item }">
            <slot :name="`filter.${field.name}`" :item="item"></slot>
          </template>
        </CRUDFilterMenu>
        <CRUDFilterQuick
          v-if="quickFilter"
          :fields="quickFilter"
          :lovs="lovs"
          @init="onFilter($event, 'init')"
          @update="onFilter($event, 'quick')"
        />

        <slot name="toolbar"></slot>
        <v-spacer></v-spacer>
        <v-divider v-if="actions.length > 0" class="mx-4" inset vertical></v-divider>

        <v-btn
          v-for="action in actions"
          text
          color="primary"
          :key="action.name"
          :disabled="action.disableIfNoIds && selected.length == 0"
          @click="onActionClick(action.name)"
        >
          {{ action.text }}
        </v-btn>
      </v-toolbar>
      <v-divider />

      <v-data-table
        class="crud-table"
        v-model="selected"
        :item-key="table.key"
        :headers="listable"
        :items="items"
        :server-items-length="totalItem"
        :options.sync="tableOption"
        @update:options="onOptionUpdate"
        @dblclick:row="onDblClick"
        v-bind="tableProp"
      >
        <template v-for="field in listable" v-slot:[`item.${field.name}`]="{ item }">
          <slot
            :name="`table.col.${field.name}`"
            :item="item"
            :field="field"
            :value="item[field.name]"
          >
            <template v-if="field.actions">
              <ActionButton
                v-for="(action, index) in field.actions"
                :key="index"
                :icon="action.icon"
                :tooltip="action.text"
                :iconBind="{ small: true }"
                @action-click="onActionClick(action.method, item[table.key])"
              />
            </template>
            <template v-else>
              {{ item[field.name] }}
            </template>
          </slot>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import CRUDApiError from "@/components/util/CRUD/CRUDApiError";
import CRUDForm from "@/components/util/CRUD/CRUDForm";
import CRUDFilterMenu from "@/components/util/CRUD/CRUDFilterMenu";
import CRUDFilterQuick from "@/components/util/CRUD/CRUDFilterQuick";
import CRUDBulkEditForm from "@/components/util/CRUD/CRUDBulkEditForm";
import ActionButton from "@/components/util/ActionButton";

const defaultAction = [
  { name: "delete", text: "Delete", disableIfNoIds: true, default: true },
  { name: "bulkEdit", text: "Bulk Edit", disableIfNoIds: true, default: false },
  { name: "add", text: "Add New", default: true }
];

const tableDefaultProp = {
  "must-sort": true,
  "single-select": false,
  "show-select": true,
  "footer-props": {
    "items-per-page-options": [10, 20, 50, 100, -1],
    "show-first-last-page": true
  },
  dense: true
};

export default {
  components: {
    CRUDApiError,
    CRUDForm,
    CRUDFilterMenu,
    CRUDFilterQuick,
    CRUDBulkEditForm,
    ActionButton
  },
  props: {
    idMethod: {
      type: String,
      default: "data",
      validator(value) {
        return ["query", "params", "data"].indexOf(value) !== -1;
      }
    },
    formSize: {
      type: String,
      default: "full"
    },
    fields: {
      type: Array,
      required: true
    },
    table: {
      type: Object,
      required: true
    },
    api: {
      type: Object,
      default() {
        return {};
      }
    },
    alwaysPostAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ApiError: null,
      formActive: false,
      idData: null,
      lovs: [],
      selected: [],
      items: [],
      totalItem: 0,
      source: [],
      loading: {
        table: false,
        form: false
      },
      params: {},
      filters: {
        dateMenu: null,
        detail: [],
        quick: []
      },
      options: {}
    };
  },
  computed: {
    formHeaderText() {
      return (this.id == "+" ? "Creating " : "Editing ") + this.table.text;
    },
    actions() {
      let actions = [...(this.table.customActions ?? [])];
      for (let action of Object.values(defaultAction)) {
        if (
          !this.table.components ||
          (action.default == true && this.table.components[action.name] != false) ||
          this.table.components[action.name]
        ) {
          actions.push(action);
        }
      }
      return actions;
    },
    tableProp() {
      return { ...tableDefaultProp, ...(this.table.default?.attrs ?? {}) };
    },
    tableOption: {
      get() {
        return Object.keys(this.options).length > 0 ? this.options : this.table.default ?? {};
      },
      set(newValue) {
        this.options = newValue;
      }
    },
    id: {
      get() {
        return this.idMethod == "data" ? this.idData : this.$route[this.idMethod].id;
      },
      set(newValue) {
        if (this.idMethod == "data") {
          this.idData = newValue;
        } else {
          let obj = {};
          obj[this.idMethod] = { id: newValue };
          this.$router.push(obj);
        }
      }
    },
    listable() {
      return this.fields
        .filter((field) => field.listable != false)
        .map((field) => {
          return { ...field, value: field.name };
        });
    },
    editable() {
      return this.fields.filter((field) => field.editable != false);
    },
    searchable() {
      return this.fields.filter((field) => field.searchable);
    },
    bulkEditable() {
      return this.fields.filter((field) => field.bulkEditable);
    },
    quickFilter() {
      return this.table.components?.quickFilter
        ?.map((q) => {
          let field = this.fields.find((f) => {
            return f.name == q.name;
          });
          return field
            ? { ...q, lov: field.input?.lov ?? [], text: q.text ?? field.text }
            : { ...q };
        })
        .filter((field) => field.name);
    },
    joins() {
      let foreigns = [];
      for (let field of Object.values(this.fields.filter((field) => field.foreign))) {
        foreigns.push({ ...field.foreign, joinWith: field.name });
      }
      return foreigns;
    }
  },
  watch: {
    id: {
      //immediate: true,
      handler(newId, oldId) {
        if (newId) {
          //reload list to show newly added row
          if (oldId == "+") {
            this.loadTable();
            if (this.formSize != "full") {
              this.$refs.crudForm.close();
            }
          } else {
            this.loadItem();
          }
          if (newId == "+") {
            this.$emit("changed:mode", "add");
          } else {
            this.$emit("changed:mode", "edit");
          }
        } else {
          this.$emit("changed:mode", "list");
        }
      }
    }
  },
  methods: {
    onFilter(values, type = "menu") {
      if (type == "menu") {
        this.filters.detail = JSON.parse(JSON.stringify(values));
      } else if (type == "quick" || type == "init") {
        this.filters.quick = [];
        if (this.quickFilter) {
          for (let filter of Object.values(this.quickFilter)) {
            let val = values[filter.name];
            if ((!Array.isArray(val) && val) || (Array.isArray(val) && val.length > 0)) {
              if (filter.type == "date-range" && val.length == 2) {
                this.filters.quick.push({
                  f: filter.name,
                  c: "><",
                  v: val
                });
              } else {
                let cond;
                switch (filter.type) {
                  case "text":
                    cond = "%";
                    break;
                  case "select":
                    cond = "=[]";
                    break;
                  default:
                    cond = "=";
                    break;
                }
                this.filters.quick.push({
                  f: filter.name,
                  c: cond, //filter.type == "text" ? "%" : "=[]",
                  v: val
                });
              }
            }
          }
        }
      }

      // on filter init, loadTable will be run from onOptionUpdate
      if (type != "init") {
        this.totalItem = 0;
        this.options.page = 1;
        this.loadTable();
      }
    },
    onBulkEdit(e) {
      let axiosObj = createAxiosObj({ method: "put" }, this.api, "bulkUpdate", {
        field: e.f,
        value: e.v,
        ids: this.selected.map((e) => {
          return e[this.table.key];
        }),
        key: this.table.key
      });

      this.axios(axiosObj)
        .then((res) => {
          this.$store.commit("sendAlert", {
            msg: res.data.status + " item(s) updated",
            color: "success"
          });
          this.selected = [];
          this.loadTable();
          this.$refs.bulkEditForm.close();
        })
        .catch((err) => {
          this.$store.commit("sendAlert", {
            msg: err,
            color: "error"
          });
          this.$refs.bulkEditForm.error();
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    onActionClick(action, params = false) {
      switch (action) {
        case "add":
          this.id = "+";
          break;
        case "edit":
          this.id = params;
          break;
        case "bulkEdit":
          this.$refs.bulkEditForm.open(this.selected);
          break;
        case "delete":
          this.$root
            .$confirm(
              "Confirm Deletion",
              "Are you sure you want to delete " +
                (this.selected.length > 1 ? `these ${this.selected.length} items?` : "this item?"),
              {
                color: "red"
              }
            )
            .then((confirm) => {
              if (confirm) {
                this.deleteItem(this.selected);
              }
            });

          break;
      }
      this.$emit("clicked:" + action, this.selected);
    },
    onOptionUpdate(e) {
      let newOption = {
        sortBy: e.sortBy,
        sortDesc: e.sortDesc,
        page: e.page,
        itemsPerPage: e.itemsPerPage
      };
      this.tableOption = newOption;
      this.loadTable();
    },
    onDblClick(x, y) {
      this.onActionClick("edit", y.item[this.table.key]);
    },
    updateValue(key, val) {
      this.$refs.crudForm.updateValue(key, val);
    },
    loadForm(values) {
      // for readonly form, inputs will be all changed to text
      // and thus need lov translate
      if (this.table.components.edit == false) {
        translateLov([values], this.fields);
      }
      this.$refs.crudForm.load(values);
      this.$emit("form:load", values);
    },
    loadItem(updateRow) {
      this.loading.form = true;
      this.formActive = true;
      return this.fetchItem()
        .then((res) => {
          if (res.data) {
            this.loadForm(res.data);
            if (updateRow) {
              let fetchedArr = { ...res.data }; //prevent shallow copy
              translateLov([fetchedArr], this.fields);
              reloadRow(this.items, fetchedArr, this.table.key);
            }
          }
        })
        .finally(() => {
          this.loading.form = false;
        });
    },
    loadTable() {
      this.loading.table = true;
      this.loading.form = true;
      Promise.all([this.fetchList(), this.fetchLov(), this.fetchItem()])
        .then((res) => {
          if (!res[0].data?.ApiError) {
            if (res[1].data) {
              populateLov(this.fields, res[1].data);
            }
            if (res[0].data) {
              this.items = res[0].data.objects;
              translateLov(this.items, this.fields);
              if (res[0].data.count) {
                this.totalItem = res[0].data.count;
              }
            }
            if (this.id) {
              this.loadForm(res[2].data);
            }
          } else {
            this.ApiError = res[0].data.ApiError;
          }
        })
        .finally(() => {
          this.loading.table = false;
          this.loading.form = false;
        });
    },
    fetchList() {
      let { sortBy, sortDesc, page, itemsPerPage } = this.tableOption;

      this.source["list"] && this.source["list"].cancel("Operation canceled due to new request.");
      this.source["list"] = axios.CancelToken.source();

      let filters = [...this.filters.detail, ...this.filters.quick];

      let axiosObj = createAxiosObj(
        { method: "get", cancelToken: this.source["list"].token },
        this.api,
        "list",
        {
          filter: filters,
          page: page,
          limit: itemsPerPage,
          orderBy: sortBy,
          orderDesc: sortDesc,
          do_count: !this.totalItem
        }
      );

      return this.axios(axiosObj)
        .then((res) => {
          return res;
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            return [];
          } else {
            return Promise.reject(thrown);
          }
        });
    },
    fetchItem() {
      if (!this.id || this.id == "+") {
        return Promise.resolve({ data: {} });
      }

      this.source["get"] && this.source["get"].cancel("Operation canceled due to new request.");
      this.source["get"] = axios.CancelToken.source();

      let axiosObj = createAxiosObj(
        { method: "get", cancelToken: this.source["get"].token },
        this.api,
        "get",
        {
          key: this.table.key,
          id: this.id
        }
      );

      return this.axios(axiosObj)
        .then((res) => {
          return res;
        })
        .catch((thrown) => {
          if (axios.isCancel(thrown)) {
            return [];
          } else {
            return Promise.reject(thrown);
          }
        });
    },
    fetchLov() {
      if ((this.lovs.length > 0 || this.joins.length == 0) && this.table.partialUpdate != false) {
        return Promise.resolve({ data: this.lovs });
      }

      let axiosObj = createAxiosObj({ method: "get" }, this.api, "lov", {
        join: this.joins
      });

      return this.axios(axiosObj).then((res) => {
        this.lovs = res.data;
        return res;
      });
    },
    deleteItem(items) {
      let idsToDelete = items.map((e) => {
        return e[this.table.key];
      });
      this.loading.table = true;

      let axiosObj = createAxiosObj({ method: "delete" }, this.api, "delete", {
        key: this.table.key,
        ids: idsToDelete
      });

      this.axios(axiosObj)
        .then((res) => {
          this.$store.commit("sendAlert", {
            msg: res.data + " item(s) deleted",
            color: "success"
          });
          this.selected = [];
          this.loadTable();
        })
        .catch((err) => {
          this.$store.commit("sendAlert", {
            msg: err,
            color: "error"
          });
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    onFormSave(data) {
      let axiosObj = createAxiosObj(
        { method: this.id == "+" ? "post" : "put" },
        this.api,
        this.id == "+" ? "create" : "update",
        {
          data: data,
          id: this.id,
          key: this.table.key
        }
      );

      this.axios(axiosObj)
        .then((res) => {
          if (res.data.status) {
            this.$store.commit("sendAlert", {
              msg: res.data.msg ?? this.table.text + " " + (this.id == "+" ? "created" : "updated"),
              color: "success"
            });
            if (this.id == "+") {
              this.id = res.data.id;
            } else {
              if (this.table.partialUpdate != false) {
                this.loadItem(true).then(() => {
                  if (this.formSize != "full") {
                    this.$refs.crudForm.close();
                  }
                });
              } else {
                this.loadTable();
                if (this.formSize != "full") {
                  this.$refs.crudForm.close();
                }
              }
            }
          } else {
            this.$store.commit("sendAlert", {
              msg: res.data.msg ?? "No update has been made",
              color: "error"
            });
          }
        })
        .finally(() => {
          this.$emit("form:saved", true);
          if (this.id) {
            this.$refs.crudForm.saved();
          }
        });
    },
    onFormClose() {
      this.id = null;
    }
  }
};

function translateLov(items, fields) {
  for (let item of Object.values(items)) {
    for (let fieldName of Object.keys(item)) {
      let fieldData = fields.find((e) => {
        return e.name == fieldName;
      });

      if (
        item[fieldName] != "" &&
        item[fieldName] != null &&
        fieldData &&
        fieldData.input?.lov &&
        !fieldData.input?.multiple &&
        !fieldData.input?.notranslate
      ) {
        let translatedValue = fieldData.input.lov.find((e) => e.value == item[fieldName])?.text;
        if (translatedValue) item[fieldName] = translatedValue;
        else item[fieldName] = `Invalid(${item[fieldName]})`;
      }
    }
  }
}

function populateLov(fields, data) {
  for (const [key, value] of Object.entries(data)) {
    let searchFieldIdx = fields.findIndex((e) => e.name == key);

    if (!fields[searchFieldIdx]?.input) {
      fields[searchFieldIdx].input = {};
    }
    if (Array.isArray(value)) {
      fields[searchFieldIdx].input.lov = value;
    }
  }

  return fields;
}

function reloadRow(tableItems, updatedItem, tableKey) {
  let row = tableItems.find((e) => {
    return e[tableKey] == updatedItem[tableKey];
  });

  if (row) {
    for (let [key, value] of Object.entries(updatedItem)) {
      if (key in row) row[key] = value;
    }
  }
}

// option used will be in this priority > user defined api, user defined default api, system default
// user defined params will be appended to system params
function createAxiosObj(defaultOpt, userDefinedApi, type, params) {
  let userDefinedOpt = {};
  if (userDefinedApi[type]) {
    userDefinedOpt = userDefinedApi[type];
  } else {
    userDefinedOpt = { ...userDefinedApi };
    userDefinedOpt.url += type;
  }
  let axiosObj = { ...defaultOpt, ...userDefinedOpt };
  let dataToSend = axiosObj.method == "get" ? "params" : "data";
  axiosObj[dataToSend] = {
    ...axiosObj[dataToSend],
    ...params
  };
  return axiosObj;
}
</script>
