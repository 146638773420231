<template>
  <div>
    <v-card v-if="page == 'listing'">
      <v-card-title v-if="cardTitle">{{ cardTitle }}</v-card-title> 
      <h1 class="api-error-msg" v-html="ApiError.Error"></h1>
      <v-card-text class="pb-0 mb-0 pt-0" v-for="(error, key) in filteredApiError" :key="key">
        <div v-if="key == 'Body'">
          <b>Body: </b>
          <prism-editor lang="xml" v-model="ApiError.Body" class="pb-4"/>
        </div>
        <p v-else class="mb-0"><b>{{key}}: </b> {{error}}</p>
      </v-card-text>
    </v-card>

    <div v-else class="apiError">
      <div class="text-center">
        <h1 v-html="ApiError.Error" class="api-error-msg"></h1>
      </div>
      <div v-for="(error, key) in filteredApiError" :key="key">
        <div v-if="key == 'Body'">
          <b>Body: </b>
          <prism-editor lang="xml" v-model="ApiError.Body" class="pb-4"/>
        </div>
        <p class="mb-0" v-else><b>{{key}}: </b> {{error}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PrismEditor from "@/components/util/PrismEditor";

export default {
  components: {
    PrismEditor,
  },

  props: ["ApiError", "cardTitle", "page"],

  computed: {
    filteredApiError() {
      let filter = JSON.parse(JSON.stringify(this.ApiError))
      delete filter['Error'];
      return filter;
    }
  },
};
</script>
<style>
  .apiError {
    height: 80vh;
  }
.api-error-msg{
	text-align:center;
	padding:10px 0px 0px 0px;
  margin: 0px 0px 10px 0px;
  font-weight: 100;
  font-size:2.5em;
  font-style: italic;
  line-height: 1.2em;
  color:#666;
  text-align:center;
}
</style>