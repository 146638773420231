var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.id)?[_c('CRUDForm',{ref:"crudForm",attrs:{"id":_vm.id,"fields":_vm.editable,"readOnly":_vm.table.components.edit == false,"formSize":_vm.formSize,"headerText":_vm.formHeaderText,"loading":_vm.loading.form,"formActive":_vm.formActive,"alwaysPostAll":_vm.alwaysPostAll},on:{"save":_vm.onFormSave,"close":_vm.onFormClose,"form:input":function($event){return _vm.$emit('form:input', $event)}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var fields = ref.fields;
var close = ref.close;
return [_vm._t("form",null,{"fields":fields,"id":_vm.id,"headerText":_vm.formHeaderText,"formSize":_vm.formSize,"close":close})]}},_vm._l((_vm.fields),function(field){return {key:("form.input." + (field.name)),fn:function(ref){
var item = ref.item;
var values = ref.values;
return [_vm._t(("form.input." + (field.name)),null,{"item":item,"values":values,"id":_vm.id})]}}}),{key:"form.append.inner",fn:function(){return [_vm._t("form.append.inner",null,{"id":_vm.id})]},proxy:true},{key:"form.append.outer",fn:function(){return [_vm._t("form.append.outer",null,{"id":_vm.id})]},proxy:true}],null,true)})]:_c('CRUDBulkEditForm',{ref:"bulkEditForm",attrs:{"fields":_vm.bulkEditable,"nameField":_vm.fields.find(function (f) {
        return f.name == (_vm.table.nameIdentifier ? _vm.table.nameIdentifier : _vm.table.key);
      })},on:{"update":_vm.onBulkEdit}}),(_vm.ApiError)?_c('CRUDApiError',{attrs:{"ApiError":_vm.ApiError,"cardTitle":_vm.table.text,"page":'listing'}}):_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.id || (_vm.id && _vm.formSize != 'full')),expression:"!id || (id && formSize != 'full')"}],attrs:{"loading":_vm.loading.table}},[_c('v-toolbar',{attrs:{"short":"","flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.table.text))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.table.components && _vm.table.components.filter)?_c('CRUDFilterMenu',{attrs:{"fields":_vm.searchable},on:{"update":_vm.onFilter},scopedSlots:_vm._u([_vm._l((_vm.fields),function(field){return {key:("filter." + (field.name)),fn:function(ref){
      var item = ref.item;
return [_vm._t(("filter." + (field.name)),null,{"item":item})]}}})],null,true)}):_vm._e(),(_vm.quickFilter)?_c('CRUDFilterQuick',{attrs:{"fields":_vm.quickFilter,"lovs":_vm.lovs},on:{"init":function($event){return _vm.onFilter($event, 'init')},"update":function($event){return _vm.onFilter($event, 'quick')}}}):_vm._e(),_vm._t("toolbar"),_c('v-spacer'),(_vm.actions.length > 0)?_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}):_vm._e(),_vm._l((_vm.actions),function(action){return _c('v-btn',{key:action.name,attrs:{"text":"","color":"primary","disabled":action.disableIfNoIds && _vm.selected.length == 0},on:{"click":function($event){return _vm.onActionClick(action.name)}}},[_vm._v(" "+_vm._s(action.text)+" ")])})],2),_c('v-divider'),_c('v-data-table',_vm._b({staticClass:"crud-table",attrs:{"item-key":_vm.table.key,"headers":_vm.listable,"items":_vm.items,"server-items-length":_vm.totalItem,"options":_vm.tableOption},on:{"update:options":[function($event){_vm.tableOption=$event},_vm.onOptionUpdate],"dblclick:row":_vm.onDblClick},scopedSlots:_vm._u([_vm._l((_vm.listable),function(field){return {key:("item." + (field.name)),fn:function(ref){
      var item = ref.item;
return [_vm._t(("table.col." + (field.name)),function(){return [(field.actions)?_vm._l((field.actions),function(action,index){return _c('ActionButton',{key:index,attrs:{"icon":action.icon,"tooltip":action.text,"iconBind":{ small: true }},on:{"action-click":function($event){return _vm.onActionClick(action.method, item[_vm.table.key])}}})}):[_vm._v(" "+_vm._s(item[field.name])+" ")]]},{"item":item,"field":field,"value":item[field.name]})]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-data-table',_vm.tableProp,false))],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }