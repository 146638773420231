<template>
  <div style="display: contents">
    <template v-for="(filter, index) in fields">
      <v-text-field
        v-if="['text', 'number'].includes(filter.type)"
        :key="index"
        :type="filter.type"
        v-model="values[filter.name]"
        clearable
        outlined
        dense
        class="ml-2"
        append-icon="mdi-magnify"
        :label="filter.text"
        single-line
        hide-details
        :style="{ maxWidth: filter.width || '200px' }"
        @input="onTextFilter($event, filter)"
      ></v-text-field>
      <v-menu
        v-else-if="filter.type == 'date-range'"
        :key="index"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
        :ref="'menu_' + index"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="ml-2"
            append-icon="mdi-calendar"
            hide-details="auto"
            clearable
            outlined
            dense
            :label="filter.text"
            :value="getDateRangeText(values[filter.name])"
            readonly
            v-bind="attrs"
            v-on="on"
            :style="{ maxWidth: filter.width || '200px' }"
            @click:clear="onClear(filter.name)"
          ></v-text-field>
        </template>
        <v-date-picker range v-model="values[filter.name]" @input="onDateRangeInput($event, index)">
        </v-date-picker>
      </v-menu>
      <v-select
        v-else
        clearable
        v-model="values[filter.name]"
        :key="index"
        :items="lovs[filter.name] ? lovs[filter.name] : filter.lov"
        :multiple="filter.multiple"
        hide-details
        dense
        :label="filter.text"
        outlined
        class="ml-2 crud-filter-select"
        :style="{ maxWidth: filter.width || '200px' }"
        @change="onFilter"
      >
        <template v-slot:selection="{ item, index }">
          <div v-if="index === 0" class="v-select__selection v-select__selection--comma">
            {{ item.text ? item.text : item }}
          </div>
          <div v-if="index === 1" class="grey--text caption">
            (+{{ values[filter.name].length - 1 }})
          </div>
        </template>
      </v-select>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      default: []
    },
    lovs: {
      default: []
    }
  },
  data: () => ({
    values: {}
  }),
  created() {
    if (this.fields) {
      let hasInitValue = false;
      for (let field of Object.values(this.fields)) {
        if (field.value) {
          this.values[field.name] = field.value;
          hasInitValue = true;
        }
      }
      if (hasInitValue) {
        this.$emit("init", this.values);
      }
    }
  },
  methods: {
    onClear(name) {
      delete this.values[name];
      this.onFilter();
    },
    getDateRangeText(dates) {
      if (Array.isArray(dates)) {
        return dates.join(" to ");
      }
      return dates;
    },
    onDateRangeInput(e, x) {
      if (e.length == 2) {
        this.$refs["menu_" + x][0].save(e);
        this.onFilter();
      }
    },
    onTextFilter(e, filter) {
      if (filter.type == "text" && e?.length > 2) {
        this.onFilter();
      } else if (filter.type == "number") {
        this.onFilter();
      } else if (!e || e.length == 0) {
        this.onClear(filter.name);
      }
    },
    onFilter() {
      this.$emit("update", this.values);
    }
  }
};
</script>

<style>
.crud-filter-select .v-select__selections {
  flex-wrap: nowrap;
}
</style>
