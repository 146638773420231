<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false" :nudge-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        v-bind="attrs"
        v-on="on"
        @click="openMenu"
        :color="filters.length > 0 ? 'accent' : null"
      >
        Filter
      </v-btn>
    </template>
    <v-card class="dialogForm">
      <v-card-text>
        <v-container fluid>
          <v-form>
            <v-row v-for="(item, index) in filtersEdit" :key="index">
              <v-col cols="2" class="pa-1">
                <v-select
                  v-model="item.condition"
                  :items="conditions[item.type]"
                  label=""
                  outlined
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="10" class="pa-1">
                <div class="d-flex">
                  <slot :name="`filter.${item.name}`" :item="item">
                    <v-select
                      v-if="item.type == 'L'"
                      v-model="item.value"
                      :items="item.lov"
                      hide-details
                      dense
                      small-chips
                      :label="item.text"
                      :multiple="item.multiple"
                      outlined
                      :ref="'filter_row_' + index"
                    >
                    </v-select>

                    <v-menu
                      v-else-if="item.type == 'D'"
                      v-model="item.dateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          hide-details
                          dense
                          outlined
                          v-model="item.value"
                          :label="item.text"
                          readonly
                          v-on="on"
                          :ref="'filter_row_' + index"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.value"
                        @input="item.dateMenu = false"
                      ></v-date-picker>
                    </v-menu>

                    <v-text-field
                      v-else
                      :value="item.value"
                      :type="item.type == 'N' ? 'Number' : 'text'"
                      @change="(v) => (item.value = v)"
                      hide-details
                      dense
                      outlined
                      :label="item.text"
                      :ref="'filter_row_' + index"
                    ></v-text-field>
                  </slot>
                  <v-btn icon @click="removeFilter(index)" class="ml-2">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-4">
              <v-col cols="12" class="pa-1">
                <v-autocomplete
                  ref="filter_select"
                  @input="addNewFilter"
                  v-model="newFilterField"
                  :items="lovs"
                  item-value="name"
                  hide-details
                  dense
                  label="Add New Filter"
                  outlined
                  :menu-props="{ maxHeight: '500' }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="menu = false">Close</v-btn>
        <v-btn @click="clearAll">Clear All</v-btn>
        <v-btn color="primary" @click="apply">Apply</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  computed: {
    lovs() {
      let lovs = JSON.parse(JSON.stringify(this.fields));
      lovs.sort(this.dynamicSort("text"));
      return lovs;
    }
  },
  props: {
    fields: {
      default() {
        return [];
      }
    }
  },
  data: () => ({
    conditions: {
      L: [
        { value: "=[]", text: "=" },
        { value: "![]", text: "!=" }
      ],
      D: [
        { value: "=", text: "On" },
        { value: ">", text: "After" },
        { value: "<", text: "Before" }
      ],
      N: [
        { value: "=", text: "=" },
        { value: "!=", text: "!=" },
        { value: ">", text: ">" },
        { value: "<", text: "<" }
      ],
      T: [
        { value: "=", text: "=" },
        { value: "!=", text: "!=" },
        { value: "^", text: "starts with" },
        { value: "!^", text: "not start with" },
        { value: "%", text: "contains" },
        { value: "!%", text: "not contain" },
        { value: "$", text: "ends with" },
        { value: "!$", text: "not end with" }
      ]
    },
    filters: [],
    filtersEdit: [],
    menu: false,
    newFilterField: null
  }),
  methods: {
    openMenu() {
      if (!this.menu) {
        this.filtersEdit = JSON.parse(JSON.stringify(this.filters));
      }
    },
    apply() {
      this.filters = JSON.parse(JSON.stringify(this.filtersEdit));
      this.menu = false;
      this.$emit(
        "update",
        this.filters.map((e) => {
          return { f: e.name, c: e.condition, v: e.value };
        })
      );
    },
    clearAll() {
      this.filtersEdit = [];
    },
    removeFilter(index) {
      this.filtersEdit.splice(index, 1);
    },
    addNewFilter() {
      let selectedField = this.fields[this.fields.findIndex((e) => e.name == this.newFilterField)];
      let fieldType = selectedField.input?.type ?? "T";
      this.filtersEdit.push({
        type: fieldType,
        text: selectedField.text,
        name: selectedField.name,
        multiple: selectedField.input?.multiple,
        lov: selectedField.input?.lov,
        condition: this.conditions[fieldType][0].value
      });
      this.$nextTick(() => {
        this.newFilterField = null;
        this.$refs.filter_select.blur();
        if (this.$refs["filter_row_" + (this.filtersEdit.length - 1)]) {
          this.$refs["filter_row_" + (this.filtersEdit.length - 1)][0].focus();
        }
      });
    }
  }
};
</script>
